.lesCardsAG {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    gap : 25px;
   
}

.filterBox {
    width: 400px;
    display : flex;
    justify-content: space-around;
    margin-top: 10px;
}

#boutonsFiltres {
    width: 400px;
    display : flex;
    justify-content: space-around;
    margin-top: 20px;
}

#compteurAG {
    margin-top: 20px;
}

.modifAG {
    display: flex;
    gap : 2px;
}

#addAGButton {
    margin-top : 20px;
}