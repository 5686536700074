nav {
	/* margin: 50px 0; */
	margin: 50px 0;
	background-color: #E75874;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
}

nav ul {
	padding: 0;
  	margin: 0;
	list-style: none;
	position: relative;
	}
	
nav ul li {
	display:inline-block;
	background-color: #E75874;
	}

nav a {
	display: block;
	padding:0 12px;	
	color:#FFF;
	font-size:20px;
	line-height: 60px;
	text-decoration:none;
}

nav a:hover { 
	background-color: #BE1558; 
}

/* Hide Dropdowns by Default */
nav ul ul {
	display: none;
	position: absolute; 
	top: 60px; /* the height of the main nav */
}
	
/* Display Dropdowns on Hover */
nav ul li:hover > ul {
	display:inherit;
}
	
/* Fisrt Tier Dropdown */
nav ul ul li {
	width: 300px;
	float:none;
	display:list-item;
	position: relative;
}

/* Second, Third and more Tiers	*/
nav ul ul ul li {
	position: relative;
	top:-60px; 
	left:170px;
}

/* .menuSignOut {
	font-size: x-small;
} */

	
/* Change this in order to change the Dropdown symbol */
/* li > a:after { content:  ' +'; }
li > a:only-child:after { content: ''; } */