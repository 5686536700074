#authText{

    position: absolute;
    width: 100rem;
    height: 7rem;
    left: 2%;
    top: 5%;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    gap:15rem;
    
    color: #FFFFFF;
}

.container {
    background-color: brown;
    color: white;
    margin-top: 250px;
}


#authAsk1 {
    position: absolute;
    width: 184px;
    height: 53px;
    left: 30%;
    top: 35%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: end;
    color: #FFFFFF;
}

#authAsk2 {
    position: absolute;
    width: 184px;
    height: 53px;
    left: 30%;
    top: 45%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: end;
    color: #FFFFFF;
}

#login {
    position: absolute;
    width: 402px;
    height: 40px;
    left: 80%;
    background: #E75874;
}

#mdp {
    position: absolute;
    width: 402px;
    height: 40px;
    left: 80%;
    background: #E75874;
}


#authOK {
    box-sizing: border-box;

    position: absolute;
    width: 142px;
    height: 40px;
    left: 50%;
    top: 60%;

    background: #E75874;
    border: 1px solid #BE1558;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;


    color: #FFFFFF;
}

#forgetMDP {
    position: absolute;
    width: 263px;
    height: 85px;
    left: 2%;
    top: 60%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-align:left;
    text-decoration: underline;
    flex-wrap: wrap;

    color: #FFFFFF;
}
